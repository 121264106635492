import React from "react";
import { Card, Descriptions } from "antd";

export const Profile = () => {
const userRole = localStorage.getItem('userRole');
const userName = localStorage.getItem('userName');
const userEmail = localStorage.getItem('userEmail');
  return (
    <div style={{ padding: "20px" }}>
      <Card title="My Account" bordered={false} style={{ width: 800 }}>
        <Descriptions layout="vertical" bordered>
          <Descriptions.Item label="Account Name">{userName}</Descriptions.Item>
          <Descriptions.Item label="Registered Email">{userEmail}</Descriptions.Item>
          <Descriptions.Item label="My Role">{userRole}</Descriptions.Item>
        </Descriptions>
      </Card>
    </div>
  );
};
export default Profile;
