import React, { useState } from 'react';
import { StarOutlined, UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Card, Input, Form, Button, Tabs, message, Upload,Tag, Row, Col  } from 'antd';
import './adminPanel.css';
import axios from 'axios';


const { TabPane } = Tabs;

// const PY_API_URL = "https://aztowaetopybackend.azurewebsites.net";
const PY_API_URL = 'https://zwewaetopybe.azurewebsites.net';
// const PY_API_URL = "http://localhost:5000/";

const AdminPanel = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Submitted',
    });
  };
  // State hooks for all inputs
  const [projectNumber, setProjectNumber] = useState('');
  const [program, setProgram] = useState('');
  const [pm, setPm] = useState('');
  const [customer, setCustomer] = useState('');
  const [project, setProject] = useState('');
  const [cdc, setCdc] = useState('');
  const [division, setDivision] = useState('');
  const [testType, setTestType] = useState('');
  const [group, setGroup] = useState('');
  const [costCenterProjectNumber, setCostCenterProjectNumber] = useState('');
  const [costCenterProgram, setCostCenterProgram] = useState('');
  const [costCenterPm, setCostCenterPm] = useState('');
  const [bckPm, setBckPm] = useState('');
  const [costCenterCdc, setCostCenterCdc] = useState('');
  const [chargeTo, setChargeTo] = useState('');
  const [fixtureName, setFixtureName] = useState('');
  const [climaticChamberName, setClimaticChamberName] = useState('');
  const [testName, setTestName] = useState('');
  const [testNumber, setTestNumber] = useState('');
  const [file, setFile] = useState(null);

  const handleProjectNumberChange = (e) => setProjectNumber(e.target.value);
  const handleProgramChange = (e) => setProgram(e.target.value);
  const handlePmChange = (e) => setPm(e.target.value);
  const handleCustomerChange = (e) => setCustomer(e.target.value);
  const handleProjectChange = (e) => setProject(e.target.value);
  const handleCdcChange = (e) => setCdc(e.target.value);
  const handleDivisionChange = (e) => setDivision(e.target.value);
  const handleTestTypeChange = (e) => setTestType(e.target.value);
  const handleGroupChange = (e) => setGroup(e.target.value);
  const handleCostCenterProjectNumberChange = (e) => setCostCenterProjectNumber(e.target.value);
  const handleCostCenterProgramChange = (e) => setCostCenterProgram(e.target.value);
  const handleCostCenterPmChange = (e) => setCostCenterPm(e.target.value);
  const handleBckPmChange = (e) => setBckPm(e.target.value);
  const handleCostCenterCdcChange = (e) => setCostCenterCdc(e.target.value);
  const handleChargeToChange = (e) => setChargeTo(e.target.value);
  const handleFixtureNameChange = (e) => setFixtureName(e.target.value);
  const handleClimaticChamberNameChange = (e) => setClimaticChamberName(e.target.value);
  const handleTestNameChange = (e) => setTestName(e.target.value);
  const handleTestNumberChange = (e) => setTestNumber(e.target.value);
  
  // Handle button clicks
  const handleSubmitProject = () => {
    try {
        const response = axios.post(`${PY_API_URL}/api/add_new_eto_project`, {
          projectNumber: projectNumber,
          program: program,
          pm: pm,
          customer: customer, 
          project: project,
          cdc: cdc,
          division: division,
        });
        // success()
        message.success("Added successfully");
      } catch (error) {
        console.error('Error submitting new eto project:', error);
      }
  };

  const handleSubmitJobType = () => {
    try {
        const response = axios.post(`${PY_API_URL}/api/add_new_job_type`, {
          testType: testType,
          group: group,
        });
        // success();
        message.success("Added successfully");
      } catch (error) {
        console.error('Error submitting new job type:', error);
      }
  };

  const handleSubmitCostCenter = () => {
    try {
        const response = axios.post(`${PY_API_URL}/api/add_cost_center`, {
          projectNumber: costCenterProjectNumber,
          program: costCenterProgram,
          pm: costCenterPm,
          bckpm: bckPm, 
          cdc: costCenterCdc,
          chargeTo: chargeTo,
        });
        // success();
        message.success("Added successfully");
      } catch (error) {
        console.error('Error submitting new cost center:', error);
      }
  };

  const handleSubmitFixture = () => {
    try {
        const response = axios.post(`${PY_API_URL}/api/add_new_fixture`, {
          fixtureName: fixtureName,
        });
        // success()
        message.success("Added successfully");
      } catch (error) {
        console.error('Error submitting new fixture:', error);
      }
  };

  const handleSubmitClimaticChamber = () => {
    try {
        const response = axios.post(`${PY_API_URL}/api/add_climatic_chamber`, {
            chamberName: climaticChamberName,
        });
        // success();
        message.success("Added successfully");
      } catch (error) {
        console.error('Error submitting new climatic chamber:', error);
      }
  };

  const handleSubmitTestInstruction = async () => {
    if (!file || !testName || !testNumber) {
      message.error("Please complete all fields and upload a file.");
      return;
    }
  
    const formData = new FormData();
    formData.append('file', file);
    formData.append('testName', testName);
    formData.append('testNumber', testNumber);
  
    try {
      const response = await axios.post(`${PY_API_URL}/api/add_new_test_instruction`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success("Test instruction added successfully");
    } catch (error) {
      message.error('Error submitting new test instruction');
      console.error('Error submitting new test instruction:', error);
    }
  };

  const handleUpload = (file) => {
    if (file.type !== 'application/pdf') {
      message.error(`${file.name} is not a PDF file.`);
      return Upload.LIST_IGNORE;
    }
    setFile(file);
    return false; // Prevent automatic upload
  };

  // Function to handle file upload
  // const handleUpload = (file) => {
  //   if (file.type !== 'application/pdf') {
  //     message.error(`${file.name} is not a PDF file.`);
  //     return Upload.LIST_IGNORE;
  //   }
  
  //   const formData = new FormData();
  //   formData.append('file', file);
  
  //   fetch(`${PY_API_URL}/api/add_new_test_instruction`, {
  //     method: 'POST',
  //     body: formData,
  //   })
  //     .then(response => response.json())
  //     .then(data => {
  //       if (data.message) {
  //         message.success("Test instruction added successfully");
  //       } else {
  //         message.error("Failed to add test instruction");
  //       }
  //     })
  //     .catch(error => {
  //       message.error("An error occurred while uploading the file.");
  //       console.error("Error:", error);
  //     });
  
  //   return true;
  // };

  // const handleChange = (info) => {
  //   if (info.file.status === 'uploading') {
  //     console.log("uploading"); // Log uploading status
  //   }
  //   if (info.file.status === 'done') {
  //     console.log('uploaded'); // Log uploaded status
  //   }
  // };

  return (
    <div className="admin-panel-container">
      <h2 style={{ display: 'inline-block', marginRight: '10px', marginBottom: '0' }}>Admin Panel</h2>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Add ETO Project" key="1">
          <Card bordered>
            <Form layout="horizontal" style={{ maxWidth: 400 }}>
              <Form.Item label="Project Number">
                <Input value={projectNumber} onChange={handleProjectNumberChange} placeholder='T70330 Carryover WR MAP'/>
              </Form.Item>
              <Form.Item label="Program">
                <Input value={program} onChange={handleProgramChange} placeholder='Carryover WR MAP'/>
              </Form.Item>
              <Form.Item label="PM">
                <Input value={pm} onChange={handlePmChange} placeholder='Paolo Casalini'/>
              </Form.Item>
              <Form.Item label="Customer">
                <Input value={customer} onChange={handleCustomerChange} placeholder='Generic'/>
              </Form.Item>
              <Form.Item label="Project">
                <Input value={project} onChange={handleProjectChange} placeholder='T70330'/>
              </Form.Item>
              <Form.Item label="CDC">
                <Input value={cdc} onChange={handleCdcChange}/>
              </Form.Item>
              <Form.Item label="Division">
                <Input value={division} onChange={handleDivisionChange} placeholder='MAP (295)'/>
              </Form.Item>
              <Form.Item>
                <Button type="primary" onClick={handleSubmitProject}>
                  Submit New ETO Project
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </TabPane>
        <TabPane tab="Add Job Type" key="2">
          <Card bordered>
            <Form layout="horizontal" style={{ maxWidth: 400 }}>
              <Form.Item label="Test Type">
                <Input value={testType} onChange={handleTestTypeChange} placeholder='ETO'/>
              </Form.Item>
              <Form.Item label="Group">
                <Input value={group} onChange={handleGroupChange} placeholder='OH'/>
              </Form.Item>
              <Form.Item>
                <Button type="primary" onClick={handleSubmitJobType}>
                  Submit New Job Type
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </TabPane>
        <TabPane tab="Add Cost Center Details" key="3">
          <Card bordered>
            <Form layout="horizontal" style={{ maxWidth: 400 }}>
              <Form.Item label="Project Number">
                <Input value={costCenterProjectNumber} onChange={handleCostCenterProjectNumberChange} placeholder='904931'/>
              </Form.Item>
              <Form.Item label="Program">
                <Input value={costCenterProgram} onChange={handleCostCenterProgramChange} placeholder='OFerrari Serratura DX CPLH'/>
              </Form.Item>
              <Form.Item label="PM">
                <Input value={costCenterPm} onChange={handleCostCenterPmChange} placeholder='Carmelo Gioffre'/>
              </Form.Item>
              <Form.Item label="BCKPM">
                <Input value={bckPm} onChange={handleBckPmChange} placeholder='Carmelo Gioffre'/>
              </Form.Item>
              <Form.Item label="CDC">
                <Input value={costCenterCdc} onChange={handleCostCenterCdcChange} placeholder='OH'/>
              </Form.Item>
              <Form.Item label="Charge To">
                <Input value={chargeTo} onChange={handleChargeToChange} placeholder='Motrol (288)'/>
              </Form.Item>
              <Form.Item>
                <Button type="primary" onClick={handleSubmitCostCenter}>
                  Submit New Cost Center Details
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </TabPane>
        <TabPane tab="Add New Fixture" key="4">
          <Card bordered>
            <Form layout="horizontal" style={{ maxWidth: 400 }}>
              <Form.Item label="Fixture Name">
                <Input value={fixtureName} onChange={handleFixtureNameChange} placeholder='Galdabini Quasar 100'/>
              </Form.Item>
              <Form.Item>
                <Button type="primary" onClick={handleSubmitFixture}>
                  Submit New Fixture
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </TabPane>
        <TabPane tab="Add New Climatic Chamber " key="5">
          <Card bordered>
            <Form layout="horizontal" style={{ maxWidth: 400 }}>
              <Form.Item label="Climatic Chamber Name">
                <Input value={climaticChamberName} onChange={handleClimaticChamberNameChange} placeholder='Camera 01 ACS'/>
              </Form.Item>
              <Form.Item>
                <Button type="primary" onClick={handleSubmitClimaticChamber}>
                  Submit New Climatic Chamber
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </TabPane>
        <TabPane tab="Add New Test Instruction" key="6">
          <Card bordered>
            <Form layout="horizontal" style={{ maxWidth: 400 }}>
              <Form.Item label="Test Name">
                <Input value={testName} onChange={handleTestNameChange} placeholder='Conditioning' />
              </Form.Item>
              <Form.Item label="Test Number" className="form-item-container">
                <div className="input-container">
                  <Input value={testNumber} onChange={handleTestNumberChange} maxLength={9} placeholder='T00001-A00' />
                </div>
                <div className="tag-container">
                  <Tag icon={<ExclamationCircleOutlined />} color="warning">
                    Make sure the test number matches the uploaded PDF file. Can only be 9 characters long
                  </Tag>
                </div>
              </Form.Item>

              <Form.Item>
                <Upload 
                  beforeUpload={handleUpload}
                  accept=".pdf"
                  maxCount={1}
                >
                  <Button icon={<UploadOutlined />}>Upload PDF</Button>
                </Upload>
              </Form.Item>
              <Form.Item>
                <Button type="primary" onClick={handleSubmitTestInstruction}>
                  Submit New Test Instruction
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default AdminPanel;
