import React, {useState, useEffect} from 'react'; 
import "./filter.css";
import ListJobs from './ListJobs';
// import DatePicker, {getAllDatesInRange} from "react-multi-date-picker"
import transition from "react-element-popper/animations/transition"
import opacity from "react-element-popper/animations/opacity"
import { LeftSquareOutlined, RightSquareOutlined, FileSearchOutlined } from '@ant-design/icons';
import axios from '../axiosConfig';  
import { customFetch } from '../customFetch'; 
import { DatePicker, Space, Select} from 'antd';
import moment from 'moment';
import { useParams, useLocation } from 'react-router-dom';



const Heatmap = ({ clicks, moves }) => {
  return (
    <>
      {clicks.map((click, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            top: click.y,
            left: click.x,
            width: '8px',
            height: '8px',
            backgroundColor: 'rgba(255, 0, 0, 0.2)',
            borderRadius: '100%',
            pointerEvents: 'none',
          }}
        />
      ))}

      {moves.map((move, index) => ( 
        <div
          key={index}
          style={{
            position: 'absolute',
            top: move.y,
            left: move.x,
            width: '8px',
            height: '8px',
            backgroundColor: 'rgba(0, 255, 0, 0.2)',
            borderRadius: '100%',
            pointerEvents: 'none',
          }}
        />
      ))}
    </>
  );
};

// const PY_API_URL = "https://aztowaetopybackend.azurewebsites.net";
// const PY_API_URL = "http://localhost:5000/";
const PY_API_URL = 'https://zwewaetopybe.azurewebsites.net';

function Filter() {

  const [filterNumber, setFilterNumber] = useState(null);
  const [jobOrderNumber, setJobOrderNumber] = useState('');

  useEffect(() => {
    // Retrieve the number from localStorage
    const number = localStorage.getItem('filterNumber');
    setFilterNumber(number);
    // localStorage.removeItem('filterNumber');
  }, []);

  useEffect(() => {
    if (filterNumber) {
      setJobOrderNumber(filterNumber);
      localStorage.removeItem('filterNumber');
    }
  }, [filterNumber]);

  console.log(jobOrderNumber);
    const [projectLeader, setProjectLeader] = useState('');
    const [Originator, setOriginator] = useState('');
    const [costCenter, setCostCenter] = useState('');
    const [status, setStatus] = useState('');
    const [jobs, setJobs] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [dates, setDates] = useState([]);
    const [filters, setFilters] = useState({});
    const [clicks, setClicks] = useState([]);
    const [moves, setMoves] = useState([]);
    const { RangePicker } = DatePicker;

    const userName = localStorage.getItem('userName');

    useEffect(() => {
      const storedClicks = JSON.parse(localStorage.getItem('homepage_clicks')) || [];
      setClicks(storedClicks);
    }, []);

    useEffect(() => {
      const storedMoves = JSON.parse(localStorage.getItem('homepage_tracker')) || [];
      setMoves(storedMoves);
    }, []);

    const handleClick = (e) => {
      const newClick = { x: e.clientX, y: e.clientY - 60 };
      const updatedClicks = [...clicks, newClick];
      setClicks(updatedClicks);
      localStorage.setItem('homepage_clicks', JSON.stringify(updatedClicks));
    };

    const handleMove = (e) => {
      const mousePosition = {x: e.clientX, y: e.clientY - 60};
      const updatedMoves = [...moves, mousePosition];
      setMoves(updatedMoves);
      localStorage.setItem('homepage_tracker', JSON.stringify(updatedMoves));
    };

    useEffect(() => {
      const fetchJobs = async () => {
        try {
          const response = await customFetch(`${PY_API_URL}/api/get_jobs`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          const sortedData = data.sort((a, b) => {
            const dateA = new Date(a.lastUpdated);
            const dateB = new Date(b.lastUpdated);
            return dateB - dateA;
          });
          setJobs(sortedData);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchJobs();
    }, []);

    // Function to extract unique values from the jobs array
    const extractUniqueValues = (key) => {
      const uniqueValues = [...new Set(jobs.map((job) => job[key]))];
      return uniqueValues.map((value, index) => (
        <option key={index} value={value}>
          {value}
        </option>
      ));
    };
    
    const handleSearch = () => {
      setFilters({
        jobOrderNumber,
        projectLeader,
        Originator,
        costCenter,
        status,
        startDate,
        endDate
      })
    };

    // handleSearch occurs as the states below update 
    useEffect(() => {
      handleSearch(); 
    }, [ jobOrderNumber, costCenter, Originator, status, startDate, endDate, projectLeader]);

    //clears all the filters
    const handleClear = async (button_id) => {      
      try {
        const response = await axios.post(`${PY_API_URL}/api/track-button-click`, {
          button_id: button_id,
          userName: userName,
          // called_at: moment(),
        });
    
        console.log('Success:', response.data);
    
        // Reset the fields
        setJobOrderNumber("");
        setProjectLeader("");
        setOriginator("");
        setCostCenter("");
        setStatus("");
        setStartDate("");
        setEndDate("");     
        setDates([]);
        handleSearch();
      } catch (error) {
        console.error('Error:', error);
      }
    };

    const [isFilterVisible, setIsFilterVisible] = useState(true);

    const handleToggleFilter = () => {
      setIsFilterVisible(!isFilterVisible);
    };

    const handleDateChange = (dates) => {
      if (dates) {
        setStartDate(dates[0].format('YYYY-MM-DD')); 
        setEndDate(dates[1].format('YYYY-MM-DD'));
      } else {
        setStartDate(null);
        setEndDate(null);
      }
    };

    
    return (
      <div className='filter-list' onClick={handleClick} onMouseMove={handleMove}>
        {isFilterVisible && (
          <div id='filter'>
            <h1><FileSearchOutlined />Find Job</h1>
    
            <label htmlFor="jobOrderNumber">Job Order Number:</label>
            <Select
              id="jobOrderNumber"
              value={jobOrderNumber}
              onChange={(value) => setJobOrderNumber(value)}
              showSearch
              placeholder="Select Job Order Number"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              style={{ width: '100%' }}
            >
              {extractUniqueValues('jobOrderNumber')}
            </Select>
            <br />
            <br />
            <label htmlFor="projectLeader">Project Leader:</label>
            <Select
              id="projectLeader"
              value={projectLeader}
              onChange={(value) => setProjectLeader(value)}
              showSearch
              placeholder="Select Project Leader"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              style={{ width: '100%' }}
            >
              {extractUniqueValues('projectLeader')}
            </Select>
            <br />
            <br />
            <label htmlFor="originator">Originator:</label>
            <Select
              id="originator"
              value={Originator}
              onChange={(value) => setOriginator(value)}
              showSearch
              placeholder="Select Originator"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              style={{ width: '100%' }}
            >
              {extractUniqueValues('originator')}
            </Select>
            <br />
            <br />
            <label htmlFor="costCenter">Cost Center:</label>
            <Select
              id="costCenter"
              value={costCenter}
              onChange={(value) => setCostCenter(value)}
              showSearch
              placeholder="Select Cost Center"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              style={{ width: '100%' }}
            >
              {extractUniqueValues('costCenter')}
            </Select>
            <br />
            <br />
            <label htmlFor="status">Status:</label>
            <Select
              id="status"
              value={status}
              onChange={(value) => setStatus(value)}
              showSearch
              placeholder="Select Status"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              style={{ width: '100%' }} 
            >
              {extractUniqueValues('status')}
            </Select>
            <br />
            <br />
    
            <label htmlFor="dateRange">Show All Jobs Between:</label>
            <RangePicker onChange={handleDateChange} />
            <br />
            <br />
            <button onClick={() => handleClear("Clear_Filter")} className='search'>Clear Filters</button>
          </div>
        )}
        <ListJobs filters={filters} />
      </div>
    );
    
  }

  export default Filter;



