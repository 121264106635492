import React, { useState, useEffect, useCallback } from 'react';
import "./listJobs.css";
import ViewJob from '../viewJob/ViewJob';
import CreateNewJob from '../createnewJob/CreateNewJob';
import Popup from 'reactjs-popup';
import { useNavigate, Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import axios from '../axiosConfig';
import { customFetch } from '../customFetch';

import { Modal, Checkbox, message, Table, Tag, Tooltip, Button, Select, Spin  } from 'antd';
import { DeleteOutlined, UsergroupDeleteOutlined, UsergroupAddOutlined } from '@ant-design/icons';

// const PY_API_URL = "https://aztowaetopybackend.azurewebsites.net";
// const PY_API_URL = "http://localhost:5000/";
const PY_API_URL = 'https://zwewaetopybe.azurewebsites.net';

function ListJobs({filters}) {

  const [sortOrder, setSortOrder] = useState('desc');
  const [jobs, setJobs] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  const [recentJobIds, setRecentJobIds] = useState([]);

  const [showPLJobs, setShowPLJobs] = useState(false);
  const [showMyJobs, setShowMyJobs] = useState(false);
  const [showCCMJobs, setShowCCMJobs] = useState(false);
  const [showUnassignedJobs, setShowUnassignedJobs] = useState(false);
  const [showSubmittedJobs, setShowSubmittedJobs] = useState(false);
  const [showSavedJobs, setShowSavedJobs] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [logData, setLogData] = useState([]);
  const userRole = localStorage.getItem('userRole');
  const userName = localStorage.getItem('userName');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [technicians, setTechnicians] = useState([]);
  const [selectedTechnicians, setSelectedTechnicians] = useState([]);
  const [selectedTTJob, setSelectedTTJob] = useState(null);

  const [ttAssignedView, setTTAssignedView] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // console.log(isLoading);

  const handleApprovedReports = (jobNum) => {
    customFetch(`${PY_API_URL}/api/getApprovedReports/${jobNum}`, {
      method: "GET",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then((data) => {
        const data_log = [];
        for (let report of data){
          const x = {
            'ReportID': 0,
            'DocumentLink': report,
            'GeneratedBy': '',
            'ReportStatus': 'Approved',
            'TestName': '',
            'TestNumber': '',
          }
          // console.log(logData);
          data_log.push(x);
          // console.log(logData);
        };
        setLogData(prevLogData => [...prevLogData, ...data_log]);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  
  
  const showModal = async (index, job) => {
    setLoading(true);
    setSelectedTTJob(job);
    console.log('the index:', index)
    try {
      const response = await customFetch(`${PY_API_URL}/api/get_ttlist`);
      const data = await response.json();
      console.log('the test technicians are: ', data)
      setTechnicians(data); 

      const currentSelectionResponse = await customFetch(`${PY_API_URL}/api/current_selections/${job.jobOrderID}`);
      const data_tt = await currentSelectionResponse.json();
    console.log("Current selections:", data_tt);
    setSelectedTechnicians(data_tt.map(tech => tech.name));
    } catch (error) {
      console.error('Failed to fetch technicians:', error);
    }
    setOpen(true);
    setLoading(false);
  };

  const handleOk = async (selectedTTJob) => {
    setLoading(true);
    // console.log('the selectedTTJob : ', selectedTTJob);
    console.log('the job order id is: ', selectedTTJob.jobOrderID)
    try {
      const response = await customFetch(`${PY_API_URL}/api/assign_technicians`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jobOrderID: selectedTTJob.jobOrderID,
          technicians: selectedTechnicians
        })
      });
  
      if (!response.ok) {
        throw new Error('Failed to assign technicians'); 
      }
  
      const result = await response.json();
      console.log('Assignment successful:', result);
      message.success('Technicians have been assigned successfully!');
    } catch (error) {
      console.error('Failed to assign technicians:', error);
      message.error('Failed to assign technicians: ' + error.message);
    }
    setLoading(false); 
    setOpen(false);
  };  
  
  

  const handleCancel = () => {
    setOpen(false);
  };

  const downloadFile = (file) => {
    customFetch(`${PY_API_URL}/api/download_file/${file}`)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.blob(); // Get the response as a Blob
        })
        .then((blob) => {
            // Create a link element
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = file;

            // Append the link to the body
            document.body.appendChild(a);

            // Simulate click
            a.click();

            // Remove the link
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
            console.error('Error fetching data:', error);
        });
    };    

    const downloadAllFiles = () => {
      logData.forEach((item) => {
        downloadFile(item.DocumentLink);
      });
    };

    const columns = [
      {
        title: "Test Number",
        dataIndex: "TestNumber",
        key: "TestNumber",
      },
      {
        title: "Test Name",
        dataIndex: "TestName",
        key: "TestName",
      },
      {
        title: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Document Link</span>
            {logData.length > 0 && (
              <Button
                type="primary"
                className="download-all-btn" 
                onClick={downloadAllFiles}
                style={{ marginLeft: 10 }}
              >
                Download All
              </Button>
            )}
          </div>
        ),
        dataIndex: "DocumentLink",
        key: "DocumentLink",
        render: (text) => (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              downloadFile(text);
            }}
          >
            {text}
          </a>
        ),
      },
      {
        title: "Generated By",
        dataIndex: "GeneratedBy",
        key: "GeneratedBy",
      },
    ];


  const filteredJobs = jobs.filter((job) => {
    if(job.projectLeader === null || job.projectLeader === undefined){
      job.projectLeader = '';
    }
    if(job.responsibility === null || job.responsibility === undefined){
      job.responsibility = '';
    }
    if(job.originator === null || job.originator === undefined){
      job.originator = '';
    }



    const isPLJob = job.projectLeader.trim() === userName;

    const isUnassignedJob = (job.projectLeader.trim() === '') && (job.submissionStatus !== false);

    if ((showPLJobs && !isPLJob) && (showUnassignedJobs && !isUnassignedJob)) {
      return false;
    } 
    else if (showPLJobs && isPLJob) {
      return true;
    } 
    else if (showUnassignedJobs && isUnassignedJob) {
      return true;
    }


    if (showPLJobs && job.projectLeader.trim() !== userName) {
      return false;
    }
    if (showCCMJobs && job.responsibility.trim() !== userName) {
      console.log('job responsibility trim', job.responsibility.trim());
      console.log('userName is ', userName)
      return false;
    }
    if (showMyJobs && job.originator.trim() !== userName) {
      return false;
    }
    if (showUnassignedJobs && job.projectLeader.trim() != '') {
      return false;
    }
    if (showSavedJobs && job.submissionStatus != false) {
      return false;
    }
    if (showSubmittedJobs && job.submissionStatus != true) {
      return false;
    }


    const meetsFilters = (!filters.jobOrderNumber || job.jobOrderNumber.trim() === filters.jobOrderNumber.trim()) && 
                         (!filters.Originator || job.originator.trim() === filters.Originator.trim()) &&
                         (!filters.projectLeader || job.projectLeader.trim() === filters.projectLeader.trim()) &&
                         (!filters.costCenter || job.costCenter.trim() === filters.costCenter.trim()) &&
                         (!filters.status || job.status.trim() === filters.status.trim()) &&
                         (!filters.startDate || ((job.lastUpdated).slice(6,10) >= (filters.startDate.slice(0,4)) && 
                         (job.lastUpdated).slice(6,10) <= (filters.endDate.slice(0,4))) && 
                         ((job.lastUpdated).slice(3,5) >= (filters.startDate.slice(5,7)) && 
                         (job.lastUpdated).slice(3,5) <= (filters.endDate.slice(5,7)))) &&
                         (job.jobActive === true); // Check if jobActive status is 1
    if (userRole === 'Project Leader') {
        return job.submissionStatus === true && meetsFilters;
    }
    if (userRole === 'Engineer') {
      return job.originator.trim() === userName && meetsFilters;
    }
    if (userRole === 'Cost Center Manager') {
      return job.responsibility.trim() === userName && meetsFilters;
    }
    // if (userRole === 'Test Technician') {
    //   return (job.status.trim() === 'Job Approved by CCM' || job.status.trim() === 'Testing In Progress') && meetsFilters;
    // }
    if (userRole === 'Test Technician') {
      // console.log('ttAssignedView is: ', ttAssignedView);
      const isAssignedJob = ttAssignedView.includes(job.jobOrderID);
      // console.log('the isassignedJob: ', isAssignedJob);
      // console.log('the job.JobOrderID: ', job.jobOrderID);
      return isAssignedJob && (job.status.trim() === 'Job Approved by CCM' || job.status.trim() === 'Testing In Progress') && meetsFilters;
    }
  
    return meetsFilters;
  });

  const handleShowMyJobsChange = (e) => {
    if(userRole === 'Project Leader'){
      setShowPLJobs(e.target.checked);
    }
    if(userRole === 'Cost Center Manager'){
      setShowCCMJobs(e.target.checked);
    }
    if(userRole === 'Engineer'){
      setShowMyJobs(e.target.checked);
    }
  };


  // useEffect(() => {
  //   const event = {
  //     target: {
  //       checked: true, 
  //     },
  //   };
  //   handleShowMyJobsChange(event);
  //   // handleShowUnassignedJobsChange(event);
  // }, []); 

  const handleShowUnassignedJobsChange = e => {
    setShowUnassignedJobs(e.target.checked);
  };

  const handleShowSavedJobsChange = e => {
    setShowSavedJobs(e.target.checked);
  };

  const handleShowSubmittedJobsChange = e => {
    setShowSubmittedJobs(e.target.checked);
  };



  // //24/11/2023 16:13 
  // console.log(filters.startDate ? filters.startDate.slice(0,4):null);
  // console.log(filters.endDate);                                                                                   
  
  //sets the row that is selected
  const handleRowClick = (index) => {
    setSelectedRow(index);
  };
  const navigate = useNavigate();

  const showConfirm_takeassignment = (index, jobNum) => {
    Modal.confirm({
      title: 'Are you sure you want to take this job?',
      onOk() {
        handleTakeAssignment(index, jobNum);
      },
      onCancel() {
        console.log('Take assignment cancelled');
      },
    });
  };

  const sendNotificationEmail = async (job, emailType) => {
    console.log('Sending notification email... jobData:', job);

    const emailData = {
        job_id: job.jobOrderID,
        jobName: job.jobName, 
        creationDate: job.creationDate,
        customer: job.customer, 
        deliveryDate: job.deliveryDate,
        depositionSamples: job.depositionSamples,
        description: job.description,
        division: job.division,
        originator: job.originator,
        projectLeader: userName,
        emailType: emailType,   
    };

    try {
        console.log('Trying the API for send job notifications now...')
        const emailResponse = await customFetch(`${PY_API_URL}/api/send_job_notification`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailData),
        });
        console.log('post send notification api call... the emailResponse is:', emailResponse);
        if (!emailResponse.ok) {
            console.error('failed to send notification email');
        }
        console.log('Notification email sent successfully.');
        message.success("Notification email sent successfully.");
    } catch (error) {
        console.error('An error occurred while sending the notification email:', error);
        message.error("Error sending notification email");
    }
};


  const handleTakeAssignment = (job, name) => {
    customFetch(`${PY_API_URL}/api/take_assignment/data`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ job_id: job.jobOrderID, name: name })
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    })
    .then(() => {
        message.success("Successfully taken the assignment!");
        sendNotificationEmail(job, 'PLTakeAssignment');
        // Redirects to /addJob with state
        navigate('/addJob', { state: { from: "viewJob", state: job } }); 
    })
    .catch(error => {
        console.error('Error taking assignment:', error);
    });
 };

  const handleEdit = (index, job) => {
    // console.log("some job info testing: ", job.EverSubmitted);
    navigate('/flowchart', {state: filteredJobs[index]})
  };

  // useEffect(() => {
  //   fetch('${PY_API_URL}/api/get_jobs')
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       let res = response.json()
  //       return res; 
  //     })
  //     .then((data) => {
  //       console.log('292, the data retuned from get_jobs', data)
  //       setJobs(data); 
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });
  // }, []);


  //handleDelete jobs
  const handleDelete = (index, jobNum) => {
    customFetch(`${PY_API_URL}/api/delete_job/${jobNum}`, {
      method: 'DELETE',
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
      .then(() => {
      const updatedJobs = jobs.map(job => {
        if (job.jobOrderID === jobNum) {
          return { ...job, jobActive: 0 }; 
        }
        return job;
      });
      setJobs(updatedJobs);
    })
    .catch(error => {
      console.error('Error deleting job in react:', error);
    });
  };

  const showConfirm_delete = (index, jobNum) => {
    Modal.confirm({
      title: 'Are you sure you want to permanently delete this job?',
      content: 'This action cannot be undone.',
      onOk() {
        handleDelete(index, jobNum);
      },
      onCancel() {
        console.log('Inactivation cancelled');
      },
    });
  };


  const showConfirm_inactivate = (index, jobNum) => {
    Modal.confirm({
      title: 'Are you sure you want to inactivate this job?',
      content: 'This action cannot be undone.',
      onOk() {
        handleInactivate(index, jobNum);
      },
      onCancel() {
        console.log('Inactivation cancelled');
      },
    });
  };



//Inactivate jobs
const handleInactivate = (index, jobNum) => {
  customFetch(`${PY_API_URL}/api/inactivate_job/${jobNum}`, {
    method: 'POST',
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then(() => {
    const updatedJobs = jobs.map(job => {
      if (job.jobOrderID === jobNum) {
        return { ...job, jobActive: 0 }; 
      }
      return job;
    });
    // if (userRole === 'Project Leader') {
    //   setJobs(updatedJobs.filter(job => job.jobActive !== 0));
    // } else {
    //   setJobs(updatedJobs);
    // }
    setJobs(updatedJobs.filter(job => job.jobActive !== false));
  })
  .catch(error => {
    console.error('Error updating job status:', error);
  });
};


const showConfirm_unassign = (index, jobNum) => {
  Modal.confirm({
    title: 'Are you sure you want to unassign this job?',
    onOk() {
      handleUnassign(index, jobNum);
    },
    onCancel() {
      console.log('Inactivation cancelled');
    },
  });
};

//Unassign jobs
const handleUnassign = (index, jobNum) => {
  customFetch(`${PY_API_URL}/api/unassign_job/${jobNum}`, {
    method: 'POST',
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then(() => {
    const updatedJobs = jobs.map(job => {
      if (job.jobOrderID === jobNum) {
        return { ...job, jobActive: 0 }; 
      }
      return job;
    });
    setJobs(updatedJobs.filter(job => job.jobActive !== false));
  })
  .catch(error => {
    console.error('Error updating job status:', error);
  });
};


// useEffect(() => {
//   // Retrieve the number from localStorage
//   const number = localStorage.getItem('filterNumber');
//   if (number) { 
//     filters.jobOrderNumber = number;
//   }
//   console.log(filters.jobOrderNumber);
// }, []);

  useEffect(() => {
    const fetchJobs = async () => {
      setIsLoading(true); 
      try {
        const response = await customFetch(`${PY_API_URL}/api/get_jobs`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const sortedData = data.sort((a, b) => {
          const dateA = new Date(a.lastUpdated);
          const dateB = new Date(b.lastUpdated);
          return dateB - dateA;
        });
        // console.log('fetching jobs, job data is: ', sortedData)
        setJobs(sortedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false); 
      }
    };
  
    fetchJobs();
  }, []);
  
  useEffect(() => {
    const userName = localStorage.getItem('userName');
    customFetch(`${PY_API_URL}/api/get_tt_assignment_data?userName=${encodeURIComponent(userName)}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log('The returned data is: ', data);
        if (data.error) {
          console.error('Error fetching data:', data.error);
          return; 
        }
        setTTAssignedView(data.jobOrderIDs || []);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);
  
  

  const handleSort = () => {
    console.log("Handle sort function called...");
    const newSortOrder = sortOrder === 'desc' ? 'asc' : 'desc';

    const sortedJobs = [...jobs].sort((a, b) => {
      const dateA = new Date(a.lastUpdated);
      const dateB = new Date(b.lastUpdated);
      return newSortOrder === 'desc' ? dateB - dateA : dateA - dateB;
    });

    setJobs(sortedJobs);
    setSortOrder(newSortOrder);
  };

  const start = Date.now();
  const millis = Date.now() - start;

  const convertToExcel = () => {
    const reorderedJobs = reorderColumns(filteredJobs, ColumnOrder);
    console.log('the filtered jobs are: ', filteredJobs);
    console.log('the ColumnOrder are: ', ColumnOrder);
  
    customFetch(`${PY_API_URL}/api/get_all_quotation`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((quotationData) => {
        if(!quotationData.error){
          console.log('data from quotation: ', quotationData);
          
          // Merge job data with quotation data
          const mergedData = mergeJobWithQuotation(reorderedJobs, quotationData);
  
          // Reorder columns with the new data
          const finalData = reorderColumns(mergedData, ColumnOrder);
  
          const ws = XLSX.utils.json_to_sheet(finalData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
          XLSX.writeFile(wb, 'Jobs.xlsx');
        };
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };
  
  const ColumnOrder = ['jobOrderID', 'jobOrderNumber', 'jobName', 'originator', 'creationDate', 'status', 'division', 'projectLeader', 'costCenter', 'costCenterManager', 'costCenterApprover', 'closingDate', 'quotationStartDate', 'quotationEndDate', 'totalCost'];
  
  const reorderColumns = (data, order) => {
    return data.map(item => {
      const reorderedItem = {};
      order.forEach(key => {
        if (item.hasOwnProperty(key)) {
          reorderedItem[key] = item[key];
        } else {
          reorderedItem[key] = ''; // Fill with empty string if the key does not exist
        }
      });
      return reorderedItem;
    });
  };
  
  const mergeJobWithQuotation = (jobs, quotations) => {
    return jobs.map(job => {
      const matchingQuotation = quotations.find(q => q.jobOrderID === job.jobOrderID);
      if (matchingQuotation) {
        return {
          ...job,
          quotationStartDate: matchingQuotation.quotationStartDate,
          quotationEndDate: matchingQuotation.quotationEndDate,
          totalCost: matchingQuotation.totalCost
        };
      } else {
        return {
          ...job,
          quotationStartDate: '',
          quotationEndDate: '',
          totalCost: ''
        };
      }
    });
  };
  
  

  // const formatDate = (dateString) => {
  //   const options = {
  //     day: 'numeric',
  //     month: 'short',
  //     year: 'numeric',
  //     hour: 'numeric',
  //     minute: 'numeric',
  //     hour12: false,
  //   };
  
  //   // Convert input date string to a JavaScript Date object (explicitly providing the input format)
  //   const dateObject = new Date(dateString.replace(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2})/, '$3-$2-$1T$4:$5'));
  
  //   const formattedDate = dateObject.toLocaleString('en-GB', options);
  //   return formattedDate.replace(',', ' @');
  // };

  // const tableHeaders = ['Job Order Number', 'Job Name', 'Current Responsibility','Originator', 'Program', 'Division','Cost Center', 'Status', 
  //                       'Last Updated', 'Project Leader', 'Submission Status', 'Actions'];

  const tableHeaders = ['Job Order Number', 'Originator', 'Job Name', 'Division', 'Cost Center', 'Project Leader', 'Submission Status', 'Status', 'Current Responsibility', 
  'Last Updated', 'Actions'];
  

  // const tableHeaders_projectleader = ['Job Order Number', 'Job Name', 'Current Responsibility','Originator', 'Program', 'Division','Cost Center', 'Status', 
  // 'Last Updated', 'Project Leader', 'Actions'];

  const tableHeaders_projectleader = ['Job Order Number', 'Originator', 'Job Name', 'Division', 'Cost Center', 'Project Leader', 'Status', 'Next Steps', 'Current Responsibility', 
  'Last Updated', 'Actions'];


  const handleViewReport = (index, jobNum) => {
    customFetch(`${PY_API_URL}/api/getApprovedReportLog/${jobNum}`, {
      method: "GET",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })
      .then((data) => {
        setLogData(data);
        handleApprovedReports(jobNum);
        setIsModalVisible(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        message.error("Failed to fetch test log.");
      });
  };


  useEffect(() => {
    console.log("use effect of fetch recent jobs")

    fetchRecentJobs(userName);
  }, [userName]); 


  const closePopup = () => {
    setSelectedRow(null);
  };
  
  // const fetchAllJobs = () => {
  //   fetch('${PY_API_URL}/api/get_jobs')
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       let res = response.json()
  //       return res; 
  //     })
  //     .then((data) => {
  //       setJobs(data); 
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });
  // };
  
  const fetchRecentJobs = (userName) => {
    customFetch(`${PY_API_URL}/api/get_recent_jobs/${userName}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (data.error) {
          console.error('Failed to fetch recent jobs:', data.error);
        } else {
          setRecentJobIds(data); 
          console.log("the recent job IDs are:", data);
        }
      })
      .catch(error => {
        console.error('Error fetching recent jobs:', error);
      });
  };
  

  return (
    <div className='listOfJobs'>
      <div className="checkbox-container">
        {/* {userRole !== 'Test Technician' && (<Checkbox defaultChecked onChange={handleShowMyJobsChange}>Show My Jobs</Checkbox>)}
        {userRole !== 'Test Technician' && userRole !== 'Engineer' && (<Checkbox defaultChecked onChange={handleShowMyJobsChange}>Show My Jobs</Checkbox>)}
        {userRole === 'Project Leader' && (<Checkbox onChange={handleShowUnassignedJobsChange}>Show Unassigned Jobs</Checkbox>)}
        {userRole === 'Engineer' && (<Checkbox onChange={handleShowSavedJobsChange}>Show Saved Jobs</Checkbox>)}
        {userRole === 'Engineer' && (<Checkbox onChange={handleShowSubmittedJobsChange}>Show Submitted Jobs</Checkbox>)}
        {(userRole === 'Project Leader' || userRole === 'Cost Center Manager' || userRole === 'Test Technician') && (<button className='excel-btn-top' onClick={convertToExcel} >
        Export to Excel
      </button>)} */}
      <div className="custom-checkbox">
        {userRole !== 'Test Technician' && userRole !== 'Engineer' && userRole !== 'Cost Center Manager'&&(
          <Checkbox onChange={handleShowMyJobsChange}>Show My Jobs</Checkbox>
        )}
        {userRole === 'Project Leader' && (
          <Checkbox onChange={handleShowUnassignedJobsChange}>Show Unassigned Jobs</Checkbox>
        )}
        {userRole === 'Engineer' && (
          <Checkbox onChange={handleShowSavedJobsChange}>Show Saved Jobs</Checkbox>
        )}
        {userRole === 'Engineer' && (
          <Checkbox onChange={handleShowSubmittedJobsChange}>Show Submitted Jobs</Checkbox>
        )}
        {(userRole === 'Project Leader' || userRole === 'Cost Center Manager' || userRole === 'Test Technician') && (<button className='excel-btn-top' onClick={convertToExcel} >
          Export to Excel 
        </button>)}
      </div>

        
      </div>
      <div className={`overlay ${selectedRow !== null ? 'active' : ''}`}></div>
      <div className={(userRole === 'Admin' || userRole === 'Engineer') ? 'tableContainer' : 'tableContainer-longer'}>
      <table>
        <thead>
          <tr>
            {(userRole !== 'Project Leader' && userRole !== 'Cost Center Manager' && userRole !== 'Admin') && (tableHeaders.map(key => (
              <th onClick={key === 'Last Updated' ? handleSort : null} style={{ cursor: key === 'Last Updated' ? 'pointer' : 'default' }}>
              {key === 'Last Updated' ? (<>{key}{sortOrder === 'desc' ? <span> &#8638;</span> : <span> &#8642;</span>} </> ) : ( key )}
            </th>
            )))}
            {(userRole === 'Project Leader' || userRole === 'Cost Center Manager' || userRole === 'Admin') && (tableHeaders_projectleader.map(key => (
              <th onClick={key === 'Last Updated' ? handleSort : null} style={{ cursor: key === 'Last Updated' ? 'pointer' : 'default' }}>
              {key === 'Last Updated' ? (<>{key}{sortOrder === 'desc' ? <span> &#8638;</span> : <span> &#8642;</span>} </> ) : ( key )}
            </th>
            )))}
          </tr>
        </thead>
          {isLoading ? (
            <div className="loading-overlay">
              <Spin tip="Loading..." size="large">
                      <div className="content" />
              </Spin>
            </div>
          ) : 
        <tbody>
        
          {filteredJobs.map((job, index) => (
              
              // <tr key={index} onClick={() => handleRowClick(index)} className={recentJobIds.includes(String(job.JobOrderID)) ? 'highlighted-row' : ''}>
              <tr key={index}  className={recentJobIds.includes(String(job.JobOrderID)) ? 'highlighted-row' : (job.SubmissionStatus === true ? 'submitted-row' : 'saved-row')}>
                {/* <td>{}</td>  */}
                <td key={index} onClick={() => handleRowClick(index)} >{job.jobOrderNumber}</td>
                <td onClick={() => handleRowClick(index)}>{job.originator}</td>
                <td onClick={() => handleRowClick(index)}>{job.jobName}</td>
                <td onClick={() => handleRowClick(index)}>{job.division}</td>
                <td onClick={() => handleRowClick(index)}>{job.costCenter}</td>
                <td onClick={() => handleRowClick(index)}>{job.projectLeader}</td>
                {(userRole !== 'Project Leader' && userRole !== 'Cost Center Manager' && userRole !== 'Admin') && (<td onClick={() => handleRowClick(index)}>{(job.submissionStatus === true ? "Submitted" : "Saved")}</td>)}
                {((job.status).includes('Pending') || (job.status).includes('Progress')) && <td onClick={() => handleRowClick(index)}><center>{<Tag color="blue" style={{ fontSize: "small", padding: "4px" }}>{job.status}</Tag>}</center></td>}
                {((job.status).includes('Approve') || (job.status).includes('Assigned')) &&<td onClick={() => handleRowClick(index)}><center>{<Tag color="green" style={{ fontSize: "small", padding: "4px" }}>{job.status}</Tag>}</center></td>}
                {((job.status).includes('Reject') || (job.status).includes('Return')) && <td onClick={() => handleRowClick(index)}><center>{<Tag color="red" style={{ fontSize: "small", padding: "4px" }}>{job.status}</Tag>}</center></td>}
                {(job.status).includes('Complete') && <td onClick={() => handleRowClick(index)}><center>{<Tag color="green" style={{ fontSize: "small", padding: "4px" }}>{job.status}</Tag>}</center></td>}
                {(userRole !== 'Engineer' && userRole !== 'Test Technician' && job.status ==='Pending Assignment') && <td onClick={() => handleRowClick(index)}>Project Leader has to take the job</td>}
                {(userRole !== 'Engineer' && userRole !== 'Test Technician' && job.status ==='Job Assigned by PL') && <td onClick={() => handleRowClick(index)}>{job.projectLeader} needs to review</td>}
                {(userRole !== 'Engineer' && userRole !== 'Test Technician' && job.status ==='Pending CCM Approval') && <td onClick={() => handleRowClick(index)}>{job.costCenterManager} needs to review</td>}
                {(userRole !== 'Engineer' && userRole !== 'Test Technician' && job.status ==='Job Approved by CCM') && <td onClick={() => handleRowClick(index)}>Awaiting {job.projectLeader} to assign test technicians</td>}
                {(userRole !== 'Engineer' && userRole !== 'Test Technician' && job.status ==='Testing In Progress') && <td onClick={() => handleRowClick(index)}>Awaiting job completion</td>}
                {(userRole !== 'Engineer' && userRole !== 'Test Technician' && job.status ==='Job Complete') && <td onClick={() => handleRowClick(index)}>No next steps</td>}
                <td onClick={() => handleRowClick(index)}>{job.responsibility}</td>
                <td onClick={() => handleRowClick(index)}>{job.lastUpdated}</td>
                <td>
                
                  <div className='button-container'>
                                        
                  {job.submissionStatus === false ? 
                      (userRole === 'Engineer' && (job.originator === userName ||job.originator.trim()  === userName) && <button
                        id='edit'
                        onClick={(e) => {
                          e.stopPropagation();  //stops popup from occuring 
                          handleEdit(index, job);
                        }}
                        title="Edit"
                      >
                        Edit
                      </button>
                      )
                      
                    : null}
                  {(userRole === 'Project Leader' || userRole === 'Admin') && (!job.projectLeader || job.projectLeader.trim() === '') &&(
                    <button type="button" 
                            className='assignment-btn' 
                            onClick={(e) => {
                                e.stopPropagation(); 
                                showConfirm_takeassignment(job, userName);
                            }}>
                        Take Assignment
                    </button>
                  )}
                  
                  {(userRole === 'Project Leader' || userRole === 'Admin')  && (job.projectLeader === userName || job.projectLeader.trim()  === userName) &&( 
                      <Link to="/addjob" state={{from: "viewJob", state: job}}>
                          <button type="button" className='view-assignment-btn'>
                              View Assignment 
                          </button>
                      </Link>
                  )}

                  {userRole === 'Cost Center Manager' && job.responsibility.trim() === userName && (
                      // <button id='eval-btn' onClick={} >Evaluate Quotation</button>
                      <Link to="/addjob" state={{from: "viewJob", state: job}}>
                        <button type="button" id='eval-btn'>
                            Evaluate Quotation
                        </button>
                    </Link>
                  )}
                    {userRole === 'Admin' && (
                      <button id='remove' onClick={(e) => {
                        e.stopPropagation();  //stops popup from occuring 
                        showConfirm_delete(index, job.jobOrderID);
                      }}
                      title="Remove"
                      >
                            <Tooltip title="Delete this job">
                              <DeleteOutlined />
                            </Tooltip>
                      </button>
                    )}
                    {job.submissionStatus === false ? 
                      (
                        userRole === 'Engineer'  
                        && 
                          (job.originator === userName ||job.originator.trim()  === userName)
                        && 
                          (job.EverSubmitted === false ) 
                        &&(
                          <button id='remove' onClick={(e) => {
                            e.stopPropagation(); 
                            showConfirm_delete(index, job.jobOrderID);
                          }}
                          title="Remove"
                          >
                            <Tooltip title="Delete this job">
                              <DeleteOutlined />
                            </Tooltip>
                          </button>
                        )
                      )
                    : null}

                    {job.submissionStatus === false ? 
                      (
                        userRole === 'Engineer'  
                        && 
                          (job.originator === userName ||job.originator.trim()  === userName)
                        && 
                          (job.EverSubmitted === true ) 
                        &&
                          (
                            <button
                              id="remove"
                              onClick={(e) => {
                                e.stopPropagation(); //stops popup from occurring
                                showConfirm_inactivate(index, job.jobOrderID);
                              }}
                              title="Inactivate"
                            >
                              <DeleteOutlined /> 
                              {/* Inactivate */}
                            </button>
                          )
                      )
                      : null
                    }

                  {
                    (userRole === "Project Leader" || userRole === 'Admin') && (job.projectLeader.trim()  === '') &&(
                      <button
                        id="remove"
                        onClick={(e) => {
                          e.stopPropagation(); //stops popup from occurring
                          showConfirm_inactivate(index, job.jobOrderID);
                        }}
                        title="Inactivate"
                      >
                            <Tooltip title="Inactivate this job">
                              <DeleteOutlined />
                            </Tooltip> 
                        {/* Inactivate */}
                      </button>
                    )
                  }
                  {
                    userRole === "Project Leader" && (job.projectLeader.trim()  === userName) && (job.status  !== 'Job Approved by CCM') && job.status !== 'Job Complete'  && job.status !== 'Testing In Progress'&&(
                      <button
                        id="unassign"
                        onClick={(e) => {
                          e.stopPropagation(); //stops popup from occurring
                          showConfirm_unassign(index, job.jobOrderID);
                        }}
                      >
                            <Tooltip title="Unassign this job">
                              <UsergroupDeleteOutlined />
                            </Tooltip>
                        
                        {/* Unassign  */}
                      </button>
                    )
                  }
                  {
                    (userRole === "Project Leader" || userRole === 'Admin') && (job.projectLeader.trim()  === userName) && (job.status  === 'Job Approved by CCM' || job.status  === 'Testing In Progress') && job.status  !== 'Job Complete' &&(
                      <button
                        id="unassign_tt"
                        onClick={(e) => {
                          e.stopPropagation(); //stops popup from occurring
                          // handleAssignTT(index, job.jobOrderID);
                          showModal(index, job);
                        }}
                      >
                            <Tooltip title="Assign Test Technian">
                              <UsergroupAddOutlined /> 
                            </Tooltip>
                        
                        {/* Unassign  */}
                      </button>
                      
                    )
                  }
                  <Modal
                    open={open}
                    mode="multiple"
                    allowClear
                    title={
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <span><small>Please select test technicians responsible for this job:</small></span>
                        <Button
                          key="submit"
                          type="primary"
                          onClick={() => handleOk(selectedTTJob)}
                          style={{ width: '100px', marginRight: '5%', marginTop: '4%' }}
                        >
                          Confirm
                        </Button>
                      </div>
                    }
                    onCancel={handleCancel}
                    maskStyle={{ backgroundColor: "transparent"}}
                    footer={[
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select Test Technicians"
                      value={selectedTechnicians}
                      onChange={setSelectedTechnicians}
                      style={{ width: '100%' }}
                    >
                      {technicians.map(tech => (
                          <Select.Option key={tech.name} value={tech.name}>{tech.name}</Select.Option>
                        ))}
                    </Select>

                  </Modal>
                  {/* {
                    userRole === "Project Leader" && (job.projectLeader.trim()  === userName) && (job.status  === 'Job Approved by CCM')&&(
                      <button
                        id="close_job"
                        onClick={(e) => {
                          e.stopPropagation(); //stops popup from occurring
                          showConfirm_unassign(index, job.jobOrderID);
                        }}
                      >
                        Close Job 
                      </button>
                    )
                  } */}


                    
                    
                    
                    {(job.status.trim() === 'Job Approved by CCM' || job.status.trim() === 'Testing In Progress') ? 
                      (userRole === 'Test Technician' && <button
                        id='editFC'
                        onClick={(e) => {
                          e.stopPropagation();  //stops popup from occuring 
                          handleEdit(index, job);
                        }}
                        title="Edit"
                      >
                        Edit Flow Chart 
                      </button>
                      )
                      
                    : null}
                    {job.status.trim() === 'Job Approved by CCM' || job.status.trim() === 'Job Complete' ? 
                      ((userRole === 'Engineer' || userRole === 'Admin') && <button
                        id='view-report'
                        onClick={(e) => {
                          e.stopPropagation();  //stops popup from occuring 
                          handleViewReport(index, job.jobOrderID);
                        }}
                        title="view-report"
                      >
                        View Reports 
                      </button>
                      
                      )
                    : null}
                    <Modal
                      title="Test Reports"
                      open={isModalVisible}
                      onCancel={() => setIsModalVisible(false)}
                      width={1000}
                      footer={null}
                      maskStyle={{ backgroundColor: "transparent"}}
                    >
                      {/* {logData.length > 0 && <Button
                        type="primary"
                        className="download-all-btn" 
                        // style={{ marginBottom: 3, maxWidth: '20%', marginLeft: '53%', backgroundColor: 'white', border: '1px solid blue', color: 'black' }}
                        onClick={downloadAllFiles}
                      >
                        Download All 
                      </Button>} */}
                      <Table
                        dataSource={logData}
                        columns={columns}
                        pagination={{ pageSize: 5 }}
                      />
                    </Modal>

                    {/* Admin can edit any job */}
                    {/* {job.submissionStatus === false ? 
                      (userRole === 'Admin' && <button
                        id='edit'
                        onClick={(e) => {
                          e.stopPropagation();  //stops popup from occuring 
                          handleEdit(index);
                        }}
                        title="Edit"
                      >
                        Edit 
                      </button>
                      )
                      
                    : null} */}
                    </div>
                  </td>
              </tr>
            ))}
        </tbody>
        }
        </table>
      </div>
      <div id='list' ><CreateNewJob className='createnew'/>
      {(userRole === 'Admin' || userRole === 'Engineer') && (<button className='excel-btn' onClick={convertToExcel} >
        Export to Excel
      </button>)}
      
      </div>
      
      
      {/* pops out the selected row by sending it to ViewJob */}
      {selectedRow !== null && (
        <Popup open={selectedRow !== null} onClose={() => setSelectedRow(null)}>
          {<ViewJob job={filteredJobs[selectedRow]} closePopup={closePopup}/>}
        </Popup>
      )}
    </div>
  );
}

export default ListJobs;






