import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Table, Button, Input, Space, Popconfirm, Select, Modal, Upload } from 'antd';
import { PlusOutlined, DeleteOutlined, EyeOutlined, UploadOutlined } from '@ant-design/icons';
import "./UniqueTest.css";
import "./DynamicTable.css";

const { TextArea } = Input;

const DynamicTable = forwardRef((props, ref) => {
  // Initial list of properties including the provided properties
  const initialProperties = [
    "IS back up free travel",
    "IS electrical release",
    "IS travel barrier (contact with metal barrier lever)",
    "IS back up mechanical realease travel",
    "IS back up MECANICAL UNLOCK TRAVEL (1 pull,from start to micro TCR)",
    "IS Full travel",
    "Over travel (values between release and full travel must be min. 2mm)",
    "IS Release Effort (with metal barrier lever)",
    "IS Return effort (at 3,5mm)",
    "OS back up free travel",
    "OS electrical release",
    "OS back up mechanical realease travel",
    "OS Full travel",
    "Over travel (values between release an full travel must be min. 2mm)",
    "OS Release Effort",
    "OS Return effort (at 3,5mm)",
    "OS Return effort (arrivare a FULL TRAVEL)",
    "OS Return effort (arrivare a META' CORSA)",
    "Latching effort",
    "Overslam (To be intended as Full travel)",
    "Distance between prim. and sec.",
    "Distance between secondary and pawl rise",
    "Distance between primary and pawl rise",
    "Door ajar switch point (from prim.) in andata",
    "Start of mechanical lock travel (42°->46°)",
    "Full travel (73°)",
    "Pretravel effort",
    "Max. Lock effort",
    "El. switch point andata (27,5°-> 49,5)",
    "El. switch point ritorno (27,5°-> 49,5)",
    "Start of mechanical unlock travel (42°->46°)",
    "Unlock to lock effort",
    "Travel da start a reset durante il ritorno",
    "Effort in reset durante il ritorno del cavo",
    "Travel from start to full travel",
    "Massimo valore di effort durante la chiusura",
    "Massimo valore di effort dove il pawl e' chiuso",
    "Minimo valore di effort nella valle",
    "Power release time (from start to stall)",
    "Power release stall current absorption",
    "Crash unlock time (from start to stall)",
    "Crash unlock stall current absorption",
    "Unlock to lock (from start to stall)",
    "Unlock stall current (target for reference)",
    "Lock to unlock (from start to stall)",
    "Lock stall current"
  ];

  const [properties] = useState(initialProperties);
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileRecords, setFileRecords] = useState({}); // Object to store file records for each cell
  const [comments, setComments] = useState({}); // Object to store comments for each cell
  const [modalTitle, setModalTitle] = useState(""); // Store the modal title for the current cell
  const [activeCellKey, setActiveCellKey] = useState(null); // Key to track the active cell (row#column)

  const [columns, setColumns] = useState([
    {
      title: 'Property',
      dataIndex: 'property',
      key: 'property',
      width: 300,
      editable: true,
      render: (text, record) => (
        <Select
          style={{ width: '100%' }}
          value={record.property}
          onChange={(value) => handlePropertyChange(value, record.key)}
        >
          {properties.map((property) => (
            <Select.Option key={property} value={property}>
              {property}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    { title: 'Unit', dataIndex: 'unit', key: 'unit', width: 50 },
    { title: 'rich. min.', dataIndex: 'richMin', key: 'richMin', width: 150 },
    { title: 'rich. max', dataIndex: 'richMax', key: 'richMax', width: 150 },
    { title: 'MIN', dataIndex: 'min', key: 'min', width: 150 },
    { title: 'MAX', dataIndex: 'max', key: 'max', width: 150 },
    { title: 'MED', dataIndex: 'med', key: 'med', width: 150 },
  ]);

  const handleAddRow = () => {
    const newData = {
      key: Date.now().toString(),
    };
    columns.forEach((col) => {
      newData[col.dataIndex] = '';
    });
    setData([...data, newData]);
  };

  const handleAddColumn = () => {
    const newColumnKey = `column-${Date.now().toString()}`;
    const newColumn = {
      title: (
        <Space>
          <Input placeholder="Sample" />
          <Popconfirm
            title="Are you sure you want to delete this column?"
            onConfirm={() => handleDeleteColumn(newColumnKey)}
          >
            <Button className='column-remove-btn' danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
      dataIndex: newColumnKey,
      key: newColumnKey,
      editable: true,
    };
    setColumns([...columns, newColumn]);

    const newData = data.map((row) => ({
      ...row,
      [newColumnKey]: '',
    }));
    setData(newData);
  };

  const handlePropertyChange = (value, key) => {
    const newData = [...data];
    const index = newData.findIndex((item) => item.key === key);
    if (index > -1) {
      newData[index] = { ...newData[index], property: value };
      setData(newData);
    }
  };

  const handleInputChange = (value, key, dataIndex) => {
    const newData = [...data];
    const index = newData.findIndex((item) => item.key === key);
    if (index > -1) {
      newData[index] = { ...newData[index], [dataIndex]: value };
      setData(newData);
    }
  };

  const handleDeleteRow = (key) => {
    const newData = data.filter((item) => item.key !== key);
    setData(newData);
  };

  const handleDeleteColumn = (key) => {
    const newColumns = columns.filter((col) => col.key !== key);
    setColumns(newColumns);

    const newData = data.map((row) => {
      const { [key]: removed, ...rest } = row;
      return rest;
    });
    setData(newData);
  };

  const handleModalOpen = (key, column, property) => {
    const cellKey = `${key}#${column}`; // Unique identifier for each cell (row#column)
    setActiveCellKey(cellKey);
    setModalTitle(`Attachments for: ${property}`);

    // Retrieve existing comments and file records for this cell
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setActiveCellKey(null);
  };

  const handleCommentChange = (e) => {
    const newComments = { ...comments, [activeCellKey]: e.target.value };
    setComments(newComments);
  };

  const handleFileUpload = ({ file }) => {
    const newFileRecords = { ...fileRecords, [activeCellKey]: file };
    setFileRecords(newFileRecords);
  };

  const columnsWithActions = columns.map((col) => {
    if (col.dataIndex === 'property') {
      return col; // Do not override the dropdown render for 'Property' column
    }
    return {
      ...col,
      width: col.dataIndex !== 'property' ? 150 : col.width,
      render: (text, record) => (
        <Space>
          <Input
            value={record[col.dataIndex]}
            onChange={(e) => handleInputChange(e.target.value, record.key, col.dataIndex)}
          />
          <EyeOutlined 
            onClick={() => handleModalOpen(record.key, col.dataIndex, record.property)} 
            style={{ cursor: 'pointer' }} 
          />
        </Space>
      ),
    };
  });

  columnsWithActions.push({
    title: '',
    key: 'actions',
    render: (_, record) => (
      <Space>
        <Popconfirm
          title="Are you sure you want to delete this row?"
          onConfirm={() => handleDeleteRow(record.key)}
        >
          <Button danger icon={<DeleteOutlined />} />
        </Popconfirm>
      </Space>
    ),
  });

  // Expose the current data and columns to the parent component
  useImperativeHandle(ref, () => ({
    getTableData: () => data,
    getColumns: () => columns,
  }));

  return (
    <div>
      <Space style={{ marginBottom: 5 }}>
        <Button onClick={handleAddRow} icon={<PlusOutlined />}>
          Add Row
        </Button>
        <Button onClick={handleAddColumn} icon={<PlusOutlined />}>
          Add Column
        </Button>
      </Space>
      <Table
        className="excel-table"
        rowClassName={() => 'excel-row'}
        bordered
        dataSource={data}
        columns={columnsWithActions}
        pagination={false}
        rowKey="key"
        scroll={{
          y: data.length > 6 ? 550 : undefined,
          x: columns.length > 10 ? 'max-content' : undefined,
        }}
      />
      
      <Modal
        title={modalTitle}
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <Upload onChange={handleFileUpload}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
        <TextArea
          rows={4}
          value={comments[activeCellKey] || ""}
          onChange={handleCommentChange}
          placeholder="Leave comments here..."
          style={{ marginTop: 16 }}
        />
      </Modal>
    </div>
  );
});

export default DynamicTable;